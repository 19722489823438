<template>
  <div class="role-permission-bind-log">
    <app-query-bar
      :queryConfig="queryConfig"
      v-model="queryData"
      @onSearch="fetchLogs" />
    <app-export-bar
      @onExport="onExport"
      @onExportAll="onExportAll" />
    <app-data-table
      :columns="LOG_COLUMNS"
      :data="logs"
      v-model="queryData"
      @onPageConfigChange="fetchLogs" />
  </div>
</template>

<script>
import { LOG_COLUMNS } from './constants';
import RolePermissionBindLogApi from './role-permission-bind-log.api';
import DataTransferUtil from '@/utils/data-transfer.util';

export default {
  name: 'role-permission-bind-log',
  data() {
    return {
      LOG_COLUMNS,
      queryConfig: [
        {
          fieldName: 'roleName',
          name: '角色名',
        },
        {
          fieldName: 'optNickName',
          name: '操作人',
        },
      ],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        roleName: '',
        optNickName: '',
      },
      logs: [],
    };
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    async fetchLogs() {
      const {
        page,
        limit,
        roleName,
        optNickName,
      } = this.queryData;
      const {
        count = 0,
        results = [],
      } = await RolePermissionBindLogApi.getLogs(
        page,
        limit,
        roleName,
        optNickName,
      );
      this.logs = results;
      this.$set(this.queryData, 'count', count);
    },
    async onExport() {
      const {
        page,
        limit,
        roleName,
        optNickName,
      } = this.queryData;
      const key = await RolePermissionBindLogApi.export({
        page,
        limit,
        role_name: roleName,
        opt_nick_name: optNickName,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await RolePermissionBindLogApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>

<style scoped>

</style>
