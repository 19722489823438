import axios from '@/plugins/axios';

class RolePermissionBindLogApi {
  static getLogs(
    page = 1,
    limit = 10,
    roleName = '',
    optNickName = '',
  ) {
    return axios({
      url: '/api/sys/log/role/permission/bind/page',
      data: {
        page,
        limit,
        role_name: roleName,
        opt_nick_name: optNickName,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询角色权限绑定日志',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/sys/log/role/permission/bind/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default RolePermissionBindLogApi;
